<template>
    <section class="page-wrap">
        <div class="content-part">
            <div class="container">
                <div class="row">
                    <div class="inner-banner other-pg-wrapper">
                        <h1>{{ page_title }}</h1>
                    </div>
                    <div class="col-xl-6 mt-2">
                        <div class="display-card">
                        <div class="contactForm">
                            <ContactForm :requestData="contactData" :textMsg="textMsg" />
                        </div> 
                    </div>
                    </div>
                    <div class="col-xl-6 mt-2">
                        <div class="map-wrapper display-card">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14133.192431324918!2d85.3340218626611!3d27.677179769195035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19f2804a02bf%3A0x85468199859b2d8d!2z4KSV4KWL4KSf4KWH4KS24KWN4KS14KSwLCDgpJXgpL7gpKDgpK7gpL7gpKHgpYzgpIEgNDQ2MDA!5e0!3m2!1sne!2snp!4v1690546183794!5m2!1sne!2snp" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import ContactForm from '../../components/ContactFrom.vue';
export default {
    name: "ContactPage",
    components:{ContactForm},
    data() {
        return {
            page_title:"Contact Us",
            contactData:{
                source: "CONTACT_FORM",
            },
            textMsg:"",
        }
    },
    mounted() {
        document.title = "Contact Us";
    },
}
</script>
